import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Insights from "../../molecules/Insights";

// Components
import StaffingHeader from "../../molecules/staffing/staffingHeader";
// import StaffingDescription from "../../molecules/staffing/staffingDescription";
// import ContactForm from "../../molecules/ContactForm";
import StaffingTechnicalCapabilities from "../../molecules/staffing/staffingTechnicalCapabilities";

import ImageBanner from "../../components/atoms/banner/imageBanner";
import { useStaticQuery, graphql } from "gatsby";

// import GokenWayImage from "../../images/servicesassets/GokenWay.png";
import DesignImage from "../../images/servicesassets/designprocee.png";
import HowWeDelieverImage from "../../images/servicesassets/howwedeliever.png";

//images
import bannerImg from "../../images/servicesassets/engineering-design.png";
// import descImg from "../../images/servicesassets/GokenWay.png";
// import descImg from "../../images/servicesassets/gokensWay.png";
import descImgMob from "../../images/servicesassets/gokensWayMobile.png";
// import SubscribeBox from "../../molecules/services/SubscribeBox";
import HalfImageDescription from "../../molecules/Common/HalfImageDescription";

const EngineeringDesign = () => {
  const data = useStaticQuery(graphql`
    query GetEngineeringDesignData {
      gcms {
        servicePages(where: { pageType: ENGINEERING_DESIGN }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap>
      <SEO
        canonicalLink={
          "https://www.goken-global.com/services/engineeringdesign/"
        }
        title="Engineering Design: Engineering Design with Goken"
        description={
          "Discover cutting-edge engineering design solutions at Goken. Our expert team brings innovation to your projects. Click to explore our services!"
        }
        // meta="Automation, KBE, knowledge based engineering, C++, Automation Services, RPA, automation studio, RPA Automation, automation technology, automation"
        facebookTitle={
          "Expert Engineering Design Services - Innovate with Goken"
        }
        facebookDescription={
          "Goken Engineering Design Company helps resolve the challenges faced by companies to develop products through our engineering design services as per client demands."
        }
        keywords={
          "engineering, design, support, upgrade, customer demand, 3D model, 2D model, design, prototype, data, detailing, conversion, product planning, product launch, computational validation, concept generation"
        }
      />
      <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
        btnTxt="Get In Touch"
        url="/contactus/"
      ></StaffingHeader>

      {/* <StaffingDescription
        noMargin={true}
        headingOne="Goken Way Of Design"
        description={
          "Our design philosophy, called the Goken Way, has evolved to meet today's design challenges. “Goken Way is a scalable and repeatable process to build high performing teams that can be productive on Day ONE”. This reduces the burden on our customer's management to provide On the Job Training. "
        }
        image={descImg}
        isHeightAuto={true}
      ></StaffingDescription> */}
      <HalfImageDescription
        lessContent={false}
        heading="Goken Way Of Product Development"
        paragraph="Our philosophy, called the Goken Way, has evolved to meet today's design challenges. “Goken Way is a scalable and repeatable process to build high performing teams that can be productive on Day ONE”. This reduces the burden on our customer's management to provide On the Job Training. "
        image={descImgMob}
        imageAltText={"Goken | Engineer Design Capabilities"}
        reverserImage={true}
        extraImageStyles={{ padding: "3rem 0", alignSelf: "flex-start" }}
      />
      {/* 
      <div>
        <StaffingDescription
          noMargin={true}
          headingOne="End to End Support"
          description={data.gcms.servicePages[0].description}
          isHeightAuto={true}
          image={DesignImage}
        ></StaffingDescription>
      </div> */}
      <HalfImageDescription
        heading="End to End Support"
        paragraph={data.gcms.servicePages[0].description}
        image={DesignImage}
      />
      {/* <StaffingDescription
        noMargin={true}
        headingOne="How We Deliver"
        description="Goken provides access to a larger talent pool of candidates and reduces the time to fill."
        image={HowWeDelieverImage}
        isHeightAuto={true}
      ></StaffingDescription> */}
      <HalfImageDescription
        heading={"How We Deliver"}
        paragraph={
          "Goken provides access to a larger talent pool of candidates and reduces the time to fill."
        }
        reverserImage={true}
        lessContent={false}
        image={HowWeDelieverImage}
      />
      <StaffingTechnicalCapabilities
        heading="Technical Capabilities"
        listItems={data.gcms.servicePages[0].technicalItems}
      ></StaffingTechnicalCapabilities>
      <div className="w-90-wrapper">
        <Insights
          insights={data.gcms.servicePages[0].insightPages}
          showTitle={true}
        ></Insights>
      </div>
      {/* <ContactForm
      
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      <ImageBanner
        heading="Learn more about Engineering Design"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>

      {/* <SubscribeBox/>  */}
    </Layout>
  );
};

export default EngineeringDesign;
