import { useEffect, useState } from "react";

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    } catch (error) {}

    // Remove the observer as soon as the component is unmounted
  }, []);

  return isIntersecting;
}
